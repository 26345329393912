import { GetStaticPaths, GetStaticProps } from 'next';

import Page from '@/components/page';
import getPageStaticPaths from '@/lib/get-page-static-paths';
import getPageStaticProps from '@/lib/get-page-static-props';
import joinSlugs from '@/lib/join-slugs';
import contentService from '@/middleware/content-service';
import mapToHistoryPageEntry from '@/middleware/mappers/pages/event/history';
import mapToSessionsPageEntry from '@/middleware/mappers/pages/event/sessions';
import mapToPageEntry from '@/middleware/mappers/pages/page';
import { PageProps } from '@/types/views/generic';

export const getStaticProps: GetStaticProps<PageProps> = async (ctx) => {
  const slugs = joinSlugs(ctx.params?.['event-slugs']);
  const eventSlugParams = (ctx.params?.['event-slugs'] as string[]) ?? [];

  const context = {
    ...ctx,
    params: {
      ...ctx.params,
      'event-slugs': ['live-session', ...eventSlugParams],
      event: 'live-session',
    },
  };

  if (slugs === 'sessions') {
    return getPageStaticProps({
      fetchPageFn: contentService.getPage(mapToSessionsPageEntry),
      getPageFullPath: ({ params }) =>
        `/${params?.event}/${params?.['event-slugs']}`,
      slugsParamName: 'event-slugs',
    })(context);
  }

  if (slugs === 'history') {
    return getPageStaticProps({
      fetchPageFn: contentService.getPage(mapToHistoryPageEntry),
      getPageFullPath: ({ params }) =>
        `/${params?.event}/${params?.['event-slugs']}`,
      slugsParamName: 'event-slugs',
    })(context);
  }

  return getPageStaticProps({
    fetchPageFn: contentService.getPage(mapToPageEntry),
    getPageFullPath: () => (slugs ? `/live-session/${slugs}` : '/live-session'),
    slugsParamName: 'event-slugs',
  })(context);
};

export const getStaticPaths: GetStaticPaths = getPageStaticPaths;

export default Page;
